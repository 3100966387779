// Buttons
%btnextend {
	display: inline-block;
	border: 1px solid;
	border-color: rgba($color_mercury,0.5) rgba($color_bon_jour,0.5) $color_mischka;
	border-radius: 3px;
	text-decoration: none;
	background: $white;
	color: $color_shark;
	&:hover {
		box-shadow: 0 0 1px 0 rgba($color_shark,0.5);
		color: $red;
	}
}
.social-btn {
	padding: 10px;
	margin: 10px;
	@extend %btnextend;
}
.btn {
	padding: 10px 22px;
	@include font-rem(14);
	margin: 4px auto;
	@extend %btnextend;
	&.btn-success {
      background-color: $success;
      color: $white;
      border-color: $success;
      &:visited {
        color: $white;
      }
      &:hover {
        background-color: $white;
        color: $success;
      }
    }
    &.btn-warning {
      background-color: $warning;
      color: $white;
      border-color: $warning;
      &:visited {
        color: $white;
      }
      &:hover {
        background-color: $white;
        color: $warning;
      }
    }
    &.btn-danger {
      background-color: $danger;
      color: $white;
      border-color: $danger;
      &:visited {
        color: $white;
      }
      &:hover {
        background-color: $white;
        color: $danger;
      }
    }
    &.btn-info {
      background-color: $info;
      color: $white;
      border-color: $info;
      &:visited {
        color: $white;
      }
      &:hover {
        background-color: $white;
        color: $info;
      }
    }
	&.btn-primary {
      background-color: $primary;
      color: $white;
      border-color: $primary;
      &:visited {
        color: $white;
      }
      &:hover {
        background-color: $white;
        color: $primary;
      }
    }
	&.btn-surprise {
      background-color: $surprise;
      color: $white;
      border-color: $surprise;
      &:visited {
        color: $white;
      }
      &:hover {
        background-color: $white;
        color: $surprise;
      }
    }
	&.btn-default {
      background-color: $default;
      color: $white;
      border-color: $default;
      &:visited {
        color: $white;
      }
      &:hover {
        background-color: $white;
        color: $default;
      }
    }
}
.title .btn {
    margin: 10px;
    @include font-rem(16);
    font-weight:300;
}
.post-list {
    .btn {
        @include font-rem(14);
    }
}

// hr break
hr {
	border: 0;
	height: 1px;
	opacity: .8;
    margin: 1em 0;
	background-image: -webkit-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
	background-image: -moz-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
	background-image: -ms-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
	background-image: -o-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
	&.hr-line {
		border: 0;
		height: 1px;
		opacity: .15;
		margin: 1em 0;
		background-image: -webkit-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
		background-image: -moz-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
		background-image: -ms-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
		background-image: -o-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
	}
}

// images and figures
img {
    display: block;
	max-width: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    border: 0;
    -ms-interpolation-mode: bicubic;
    image-rendering: optimizeQuality;
}
.img-circle {
	border-radius: 50%;
	border: 3px solid $white;
	height: 150px;
	width: 150px;
}
.author-photo {
    background-color: $white;
}
.image-right {
    float: right;
    margin-left: 1em;
    margin-top: 0;
}
figure {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  @include clearfix();
  img {
    margin-bottom: 10px;
    @include rounded(4px);
  }
  &.half {
    @media only screen and (min-width: 62.5em) {
      img {
        width: calc(50% - 4px);
        float: left;
        margin-left: 1px;
        margin-right: 1px;
      }
      figcaption {
        clear: left;
      }
    }
  }
  &.third {
    @media only screen and (min-width: 62.5em) {
      img {
        width: calc(33.3% - 6px);
        float: left;
        margin-left: 1px;
        margin-right: 1px;
      }
      figcaption {
        clear: left;
      }
    }
  }
}
svg:not(:root) {
  overflow: hidden; 
}

// keyboard tag
kbd {
	display: inline-block;
	margin: 0 1px;
	padding: 1px 8px;
	@include font-rem(10);
	font-weight: bold;
	color: $color_fuscous_gray;
	vertical-align: middle;
	background-color: $color_alabaster;
	border: solid 1px $color_celeste;
	border-bottom-color: $color_pink_swan;
	border-radius: 3px;
	box-shadow: inset 0 -1px 0 $color_pink_swan;
}

// go up button
.goup-container {
    border-radius: 3px !important;
    border-color: rgba($color_mercury,0.5) rgba($color_bon_jour,0.5) $color_mischka !important;
    border: 1px solid;
	@media #{$small} {
		display: none !important;
	}
}
.goup-arrow {
    border-color: transparent transparent $color_tuatara !important;
}

// reading time
.reading-time {
    text-align: center;
    font-weight: 300;
    margin-bottom: 10px;
}
